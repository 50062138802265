'use client'

import IntercomProvider from '../providers/IntercomProvider'
import Error from './error'

export default function GlobalError(props: { error: Error & { digest?: string } }) {
  return (
    <IntercomProvider>
      <Error {...props} />
    </IntercomProvider>
  )
}
